export function register() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/service-worker.js')
        .catch((error) => {
          console.error('Failed to register the Service Worker:', error);
        });
    });
  }
}
