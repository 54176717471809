import { Suspense, lazy } from "react";

import { AuthGuard } from "src/auth/guard";
import DashboardLayout from "src/layouts/dashboard";
import { LoadingScreen } from "src/components/loading-screen";
import { Outlet } from "react-router-dom";

const AiAcquisitionPage = lazy(
    () => import("src/pages/dashboard/aipilot/acquisition"),
);

const AiConversionPage = lazy(
    () => import("src/pages/dashboard/aipilot/conversion"),
);
const AiExperiencePage = lazy(
    () => import("src/pages/dashboard/aipilot/experience"),
);
const AiEngagementPage = lazy(
    () => import("src/pages/dashboard/aipilot/engagement"),
);

// ADMIN
const AdminBookingsPage = lazy(
    () => import("src/pages/dashboard/admin/admin-bookings"),
);
const AdminBookingsDetailsPage = lazy(
    () => import("src/pages/dashboard/admin/admin-bookings-details"),
);

const AdminBookingsVoucherPage = lazy(
    () => import("src/pages/dashboard/admin/admin-booking-voucher-view"),
);

const AdminSalesReportPage = lazy(
    () => import("src/pages/dashboard/admin/admin-sales-report"),
);
const AdminTzClientsPage = lazy(
    () => import("src/pages/dashboard/admin/admin-tzclients"),
);
const AdminTzDepartmentsPage = lazy(
    () => import("src/pages/dashboard/admin/admin-tzdepartments"),
);
const AdminTzTravelPoliciesPage = lazy(
    () => import("src/pages/dashboard/admin/admin-tztravelPolicies"),
);
const AdminTzClientDetailsPage = lazy(
    () => import("src/pages/dashboard/admin/admin-tzclients-details"),
);
const AdminTzClientsReportPage = lazy(
    () => import("src/pages/dashboard/admin/admin-tzclients-report"),
);

const AdminAgencyNewPage = lazy(
    () => import("src/pages/dashboard/admin/admin-agency-new"),
);

// OVERVIEW
const IndexPage = lazy(() => import("src/pages/dashboard/app"));
const HomePage = lazy(() => import("src/pages/dashboard/home"));
// BOOKING
const BookingListPage = lazy(() => import("src/pages/dashboard/booking/list"));
const BookingDetailsPage = lazy(
    () => import("src/pages/dashboard/booking/details"),
);
// INVOICE
const InvoiceListPage = lazy(() => import("src/pages/dashboard/invoice/list"));
const InvoiceDetailsPage = lazy(
    () => import("src/pages/dashboard/invoice/details"),
);
const InvoiceCreatePage = lazy(() => import("src/pages/dashboard/invoice/new"));
const InvoiceEditPage = lazy(() => import("src/pages/dashboard/invoice/edit"));

// PROJECT
const ProjectListPage = lazy(() => import("src/pages/dashboard/project/list"));
const ProjectProfilePage = lazy(
    () => import("src/pages/dashboard/project/profile"),
);
const ProjectCreatePage = lazy(() => import("src/pages/dashboard/project/new"));

// USER
const UserProfilePage = lazy(() => import("src/pages/dashboard/user/profile"));
const UserCardsPage = lazy(() => import("src/pages/dashboard/user/cards"));
const UserListPage = lazy(() => import("src/pages/dashboard/user/list"));
const UserAccountPage = lazy(() => import("src/pages/dashboard/user/account"));
const UserVirtualCardPage = lazy(
    () => import("src/pages/dashboard/user/virtualCard"),
);
const UserCreatePage = lazy(() => import("src/pages/dashboard/user/new"));
const UserEditPage = lazy(() => import("src/pages/dashboard/user/edit"));

// DEPARTMENT

const DepartmentListPage = lazy(
    () => import("src/pages/dashboard/department/list"),
);
const DepartmentCreatePage = lazy(
    () => import("src/pages/dashboard/department/new"),
);
const DepartmentEditPage = lazy(
    () => import("src/pages/dashboard/department/edit"),
);

// DEPARTMENT

const TravelPolicyListPage = lazy(
    () => import("src/pages/dashboard/travelPolicy/list"),
);
const TravelPolicyCreatePage = lazy(
    () => import("src/pages/dashboard/travelPolicy/new"),
);
const TravelPolicyEditPage = lazy(
    () => import("src/pages/dashboard/travelPolicy/edit"),
);

//APPROVALS

const ApprovalListPage = lazy(() => import("src/pages/dashboard/approvals"));

//REPORTS

const ReportsPage = lazy(() => import("src/pages/dashboard/Reports"));

// Expenses
const ExpenseProfilePage = lazy(
    () => import("src/pages/dashboard/expense/details"),
);
const ExpenseListPage = lazy(() => import("src/pages/dashboard/expense/list"));
const ExpenseCreatePage = lazy(() => import("src/pages/dashboard/expense/new"));
const ExpenseEditPage = lazy(() => import("src/pages/dashboard/expense/edit"));
const ExpenseReportPage = lazy(
    () => import("src/pages/dashboard/expense/report"),
);

// Expenses
const SpendingLimitsListPage = lazy(
    () => import("src/pages/dashboard/spendingLimits/list"),
);
const NewSpendingLimitsPage = lazy(
    () => import("src/pages/dashboard/spendingLimits/new"),
);
const SpendingLimitsDetailsPage = lazy(
    () => import("src/pages/dashboard/spendingLimits/details"),
);
const SpendingLimitsEditPage = lazy(
    () => import("src/pages/dashboard/spendingLimits/edit"),
);

// ACTIVITY
const ActivityListPage = lazy(
    () => import("src/pages/dashboard/activity/list"),
);
const ActivityDetailsPage = lazy(
    () => import("src/pages/dashboard/activity/details"),
);
const ActivityCheckoutPage = lazy(
    () => import("src/pages/dashboard/activity/checkout"),
);
// HOTEL VIEW
const HotelListPage = lazy(() => import("src/pages/dashboard/hotel/list"));
const HotelEditPage = lazy(() => import("src/pages/dashboard/hotel/edit"));
const HotelDetailsPage = lazy(
    () => import("src/pages/dashboard/hotel/details"),
);
const HotelCheckoutPage = lazy(
    () => import("src/pages/dashboard/hotel/checkout"),
);

// FLIGHTS
const FlightHomePage = lazy(() => import("src/pages/dashboard/flight"));
const FlightCheckoutPage = lazy(
    () => import("src/pages/dashboard/flight/checkout"),
);

// TRASNFERS
const TransferHomePage = lazy(() => import("src/pages/dashboard/transfer"));
const TransferCheckoutPage = lazy(
    () => import("src/pages/dashboard/transfer/checkout"),
);

// CAR RENTAL
const CarRentalHomePage = lazy(() => import("src/pages/dashboard/carRental"));
const CarRentalCheckoutPage = lazy(
    () => import("src/pages/dashboard/carRental/checkout"),
);

// CLIENT VIEW
const ClientViewPage = lazy(() => import("src/pages/dashboard/client-view"));
const ClientBookViewPage = lazy(
    () => import("src/pages/dashboard/client-book-view"),
);
// PROVIDER VIEW
const ProviderViewPage = lazy(
    () => import("src/pages/dashboard/provider-view"),
);

// FILE MANAGER
const FileManagerPage = lazy(() => import("src/pages/dashboard/file-manager"));
// APP
const ChatPage = lazy(() => import("src/pages/dashboard/chat"));
const MailPage = lazy(() => import("src/pages/dashboard/mail"));
const CalendarPage = lazy(() => import("src/pages/dashboard/calendar"));
const KanbanPage = lazy(() => import("src/pages/dashboard/kanban"));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(
    () => import("src/pages/dashboard/permission"),
);

//CRUISE
const CruiseListPage = lazy(() => import("src/pages/dashboard/cruise/list"));
const CruiseDetailsPage = lazy(
    () => import("src/pages/dashboard/cruise/details"),
);
// ----------------------------------------------------------------------

export const dashboardRoutes = [
    {
        path: "dashboard",
        element: (
            <AuthGuard>
                <DashboardLayout>
                    <Suspense fallback={<LoadingScreen />}>
                        <Outlet />
                    </Suspense>
                </DashboardLayout>
            </AuthGuard>
        ),
        children: [
            // { element: <IndexPage />, index: true },
            { element: <HomePage />, index: true },
            { path: "home", element: <HomePage /> },
            {
                path: "admin",
                children: [
                    { path: "bookings", element: <AdminBookingsPage /> },
                    { path: ":id", element: <AdminBookingsDetailsPage /> },
                    {
                        path: "tzclient/:id",
                        element: <AdminTzClientDetailsPage />,
                    },
                    { path: "salesreport", element: <AdminSalesReportPage /> },
                    { path: "agencies", element: <AdminTzClientsPage /> },
                    {
                        path: "departments",
                        element: <AdminTzDepartmentsPage />,
                    },
                    {
                        path: "travelPolicies",
                        element: <AdminTzTravelPoliciesPage />,
                    },
                    {
                        path: "clientsreport", // !Is important
                        element: <AdminTzClientsReportPage />,
                    },
                    {
                        path: "bookingVoucher/:id",
                        element: <AdminBookingsVoucherPage />,
                    },
                    { path: "new-agency", element: <AdminAgencyNewPage /> },
                ],
            },
            {
                path: "project", // Add a forward slash before the path
                children: [
                    { element: <UserProfilePage />, index: true },
                    { path: "list", element: <ProjectListPage /> },
                    {
                        path: "profile/:projectId",
                        element: <ProjectProfilePage />,
                    },
                    { path: "new", element: <ProjectCreatePage /> },
                ],
            },
            {
                path: "user",
                children: [
                    { element: <UserProfilePage />, index: true },
                    { path: "profile", element: <UserProfilePage /> },
                    { path: "cards", element: <UserCardsPage /> },
                    { path: "list", element: <UserListPage /> },
                    { path: "new", element: <UserCreatePage /> },
                    { path: ":id/edit", element: <UserEditPage /> },
                    { path: "account", element: <UserAccountPage /> },
                    { path: "virtualCard", element: <UserVirtualCardPage /> },
                ],
            },
            {
                path: "department",
                children: [
                    { path: "list", element: <DepartmentListPage /> },
                    { path: "new", element: <DepartmentCreatePage /> },
                    { path: ":id/edit", element: <DepartmentEditPage /> },
                ],
            },
            {
                path: "travelPolicy",
                children: [
                    { path: "list", element: <TravelPolicyListPage /> },
                    { path: "new", element: <TravelPolicyCreatePage /> },
                    { path: ":id/edit", element: <TravelPolicyEditPage /> },
                ],
            },
            {
                path: "spendingLimits",
                children: [
                    { path: "list", element: <SpendingLimitsListPage /> },
                    { path: "new", element: <NewSpendingLimitsPage /> },
                    {
                        path: ":id/profile",
                        element: <SpendingLimitsDetailsPage />,
                    },
                    {
                        path: ":id/edit",
                        element: <SpendingLimitsEditPage />,
                    },
                ],
            },
            {
                path: "approval",
                children: [{ path: "list", element: <ApprovalListPage /> }],
            },
            {
                path: "reports",
                children: [{ element: <ReportsPage />, index: true }],
            },
            //===============
            {
                path: "expense",
                children: [
                    { element: <ExpenseProfilePage />, index: true },
                    {
                        path: "profile/:expenseId",
                        element: <ExpenseProfilePage />,
                    },
                    { path: "list", element: <ExpenseListPage /> },
                    { path: "report", element: <ExpenseReportPage /> },
                    { path: "new", element: <ExpenseCreatePage /> },
                    { path: ":id/edit", element: <ExpenseEditPage /> },
                ],
            },
            {
                path: "booking",
                children: [
                    { element: <BookingListPage />, index: true },
                    { path: "list", element: <BookingListPage /> },
                    { path: ":id", element: <BookingDetailsPage /> },
                ],
            },
            {
                path: "invoice",
                children: [
                    { element: <InvoiceListPage />, index: true },
                    { path: "list", element: <InvoiceListPage /> },
                    { path: ":id", element: <InvoiceDetailsPage /> },
                    { path: ":id/edit", element: <InvoiceEditPage /> },
                    { path: "new", element: <InvoiceCreatePage /> },
                ],
            },
            {
                path: "activity",
                children: [
                    { element: <ActivityListPage />, index: true },
                    { path: "list", element: <ActivityListPage /> },
                    {
                        path: ":productCode/:gdsprovider",
                        element: <ActivityDetailsPage />,
                    },
                    {
                        path: "checkout/:code",
                        element: <ActivityCheckoutPage />,
                    },
                ],
            },
            {
                path: "hotel",
                children: [
                    { element: <HotelListPage />, index: true },
                    { path: "list", element: <HotelListPage /> },
                    {
                        path: "hotelDetails/:gdsprovider/:code",
                        element: <HotelDetailsPage />,
                    },
                    { path: ":code/edit", element: <HotelEditPage /> },
                    {
                        path: "checkout/:code/:rateKey",
                        element: <HotelCheckoutPage />,
                    },
                ],
            },

            {
                path: "flight",
                children: [
                    { element: <FlightHomePage />, index: true },
                    {
                        path: "checkout/:offerId",
                        element: <FlightCheckoutPage />,
                    },
                ],
            },

            {
                path: "transfer",
                children: [
                    { element: <TransferHomePage />, index: true },
                    {
                        path: "checkout/:offerId",
                        element: <TransferCheckoutPage />,
                    },
                ],
            },

            {
                path: "aipilot",
                children: [
                    { element: <AiAcquisitionPage />, index: true },
                    { path: "acquisition", element: <AiAcquisitionPage /> },
                    { path: "conversion", element: <AiConversionPage /> },
                    { path: "experience", element: <AiExperiencePage /> },
                    { path: "engagement", element: <AiEngagementPage /> },
                ],
            },

            {
                path: "carRental",
                children: [
                    { element: <CarRentalHomePage />, index: true },
                    {
                        path: "checkout/:offerId",
                        element: <CarRentalCheckoutPage />,
                    },
                ],
            },
            {
                path: "cruises",
                children: [
                    { element: <CruiseListPage />, index: true },
                    { path: "details/:id", element: <CruiseDetailsPage /> },
                ],
            },

            { path: "client-view", element: <ClientViewPage /> },
            { path: "provider-view", element: <ProviderViewPage /> },
            { path: "client-book-view", element: <ClientBookViewPage /> },
            { path: "file-manager", element: <FileManagerPage /> },
            { path: "mail", element: <MailPage /> }, // !Is important?
            { path: "chat", element: <ChatPage /> }, // !Is important?
            { path: "calendar", element: <CalendarPage /> }, // !Is important?
            { path: "kanban", element: <KanbanPage /> }, // !Is important?
            { path: "permission", element: <PermissionDeniedPage /> }, // !Is important?
        ],
    },
];
