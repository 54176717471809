/**
 * @typedef {Object} AuthReducerState
 * @property {null | AuthUser} user
 * @property {boolean} loading
 * @property {string[]} paymentMethods
 * @property {boolean} isValidSubscription
 * @property {null | any} travelPolicies
 */
/**
 * @typedef {Object} AuthUser
 * @property {string} token
 * @property {string} currency
 * @property {string[]} paymentMethods
 * @property {number} applicableTax
 * @property {Object} fees
 * @property {number} fees.hotelsFee
 * @property {number} fees.activitiesFee
 * @property {number} fees.transfersFee
 * @property {number} fees.carsFee
 * @property {number} fees.flightsInternationalFee
 * @property {number} fees.flightsDomesticFee
 * @property {number} fees.cruisesFee
 * @property {number} fees.packagesFee
 *
 * @property {string} name
 * @property {string} address
 * @property {string} phone
 * @property {string} email
 * @property {string} country
 * @property {string} _id
 * @property {string} userId
 * @property {string} userCity
 * @property {number} userLat
 * @property {number} userLong
 * @property {string} userRole
 * @property {string} role
 * @property {string} subscriptionPlan
 * @property {string} language
 *
 * @property {Object} company
 * @property {string} company._id
 * @property {string} company.companyId
 * @property {string} company.avatarUrl
 * @property {string} company.companyName
 * @property {string} company.companyParentName
 * @property {boolean} company.parent
 * @property {Object} company.avatarurlParent
 * @property {string} company.logoWidth
 * @property {string} company.bankName
 * @property {string} company.bankAccount
 * @property {string} company.bankAccountOwner
 * @property {string} company.email
 * @property {string} company.swift
 * @property {boolean} company.isParent
 * @property {number} company.onboardingStatus
 *
 */
/**
 * @type {AuthReducerState}
 */
export const initialState = {
    user: null,
    loading: true,
    paymentMethods: [],
    isValidSubscription: false,
    travelPolicies: null,
};
