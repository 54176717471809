import PropTypes from "prop-types";
import { SplashScreen } from "src/components/loading-screen";
import SlidingMessage from "./SlidingMessage";
import { AuthContext } from "./auth-context";

export function AuthConsumer({ children }) {
    return (
        <AuthContext.Consumer>
            {(auth) => {
                // Show the loading screen if the authentication context is still loading
                if (auth.loading) {
                    return <SplashScreen />;
                }

                // If the user is logged in but does not have a valid subscription plan, show the sliding message
                if (auth.authenticated && !auth.isValidSubscription) {
                    return (
                        <>
                            {children}
                            <SlidingMessage />
                        </>
                    );
                }

                // Otherwise, render the children components normally
                return children;
            }}
        </AuthContext.Consumer>
    );
}

AuthConsumer.propTypes = {
    children: PropTypes.node,
};
