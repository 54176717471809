import { getAirportsList } from "./getAirportList";

export const parseFlightData = async (tripData) => {
    const { departureCity, cabinClass, destinations } = tripData;
    if (!Array.isArray(destinations)) {
        return [];
    }
    const cities = await Promise.all(
        [departureCity, ...destinations].map(async (destination) => {
            const destinationName =
                destination?.city?.name || destination?.name;
            const destinationCountry =
                destination?.city?.country || destination.country;

            const response = (await getAirportsList(destinationName)).filter(
                (d) => d.country_name === destinationCountry,
            );
            const exactMatch =
                response.find(
                    (d) =>
                        d.type === "airport" && d.city_name === destinationName,
                ) ||
                response.find(
                    (d) => d.type === "city" && d.name === destinationName,
                );
            if (exactMatch) {
                return {
                    name: destinationName,
                    code: exactMatch.code,
                };
            }
            const airports = response.sort((a, b) => {
                if (a.type === "airport" && b.type !== "airport") {
                    return -1; // a va antes que b
                } else if (a.type !== "airport" && b.type === "airport") {
                    return 1; // b va antes que a
                }
                return 0; // no cambia el orden relativo
            });
            return {
                name: destinationName,
                code: airports?.[0]?.code,
            };
        }),
    );
    const slices = [];
    slices.push({
        origin: cities[0].code,
        destination: cities[1].code,
        departure_date: new Date(destinations[0].startDate)
            .toISOString()
            .split("T")[0],
        itineraryCity: cities?.[0]?.name?.toLowerCase() || "",
        type: "departure",
    });
    for (let i = 1; i < cities.length - 1; i++) {
        slices.push({
            origin: cities[i].code,
            destination: cities[i + 1].code,
            departure_date: new Date(destinations[i].startDate)
                .toISOString()
                .split("T")[0],
            itineraryCity: cities?.[i]?.name?.toLowerCase(),
            type: "departure",
        });
    }

    slices.push({
        origin: cities[cities.length - 1].code,
        destination: cities[0].code,
        departure_date: new Date(destinations[destinations.length - 1].endDate)
            .toISOString()
            .split("T")[0],
        itineraryCity: cities?.[cities.length - 1]?.name?.toLowerCase(),
        type: "arrival",
    });

    const passengers = [];
    passengers.push({
        type: "adult",
    });

    return {
        slices,
        passengers,
        cabin_class: cabinClass,
        currency: "EUR",
    };
};
