import PropTypes from "prop-types";
// @mui
// hooks
import { useBoolean } from "src/hooks/use-boolean";
import { useResponsive } from "src/hooks/use-responsive";
// components
//
import Main from "./main";
import Header from "./header";
import NavVertical from "./nav-vertical";
import NavHorizontal from "./nav-horizontal";

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
    const lgUp = useResponsive("up", "lg");

    const nav = useBoolean();

    return (
        <>
            <Header onOpenNav={nav.onTrue} />

            {lgUp ? (
                <NavHorizontal />
            ) : (
                <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />
            )}

            <Main>{children}</Main>
        </>
    );
}

DashboardLayout.propTypes = {
    children: PropTypes.node,
};
